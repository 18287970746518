"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Manifest = void 0;
const bundles_enum_1 = require("../enums/bundles.enum");
exports.Manifest = {
    bundles: [
        {
            name: bundles_enum_1.BundlesEnum.FONTS,
            assets: [
                {
                    alias: "FiraGo",
                    src: "assets/fonts/FiraGO-Regular.woff2",
                },
                {
                    alias: "FiraGoSemiBold",
                    src: "assets/fonts/FiraGO-SemiBold.woff2",
                },
                {
                    alias: "FiraGoBold",
                    src: "assets/fonts/FiraGO-Bold.woff2",
                },
            ],
        },
        {
            name: bundles_enum_1.BundlesEnum.MAIN,
            assets: [
                {
                    alias: "background",
                    src: "assets/images/bg.jpg",
                },
            ],
        },
        {
            name: bundles_enum_1.BundlesEnum.BUNDLE_1,
            assets: [
                {
                    alias: "f22",
                    src: "assets/images/f22.png",
                },
                {
                    alias: "guy",
                    src: "assets/images/guy.json",
                },
            ],
        },
    ],
};

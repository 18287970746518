"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const bundles_enum_1 = require("../enums/bundles.enum");
class ResourcesModel {
    constructor() { }
    getTexture(key, bundle = bundles_enum_1.BundlesEnum.MAIN) {
        return this.loaded[bundle][key];
    }
    getTextures(key, bundle = bundles_enum_1.BundlesEnum.MAIN) {
        // return this.loaded[bundle][key].animations["pixels_large"];
        return Object.values(this.loaded[bundle][key].textures);
    }
}
exports.default = ResourcesModel;
